import React from 'react';
// import '../../assets/sass/_masthead.scss';
export default function Product() {
    return (
        <div>
          <div className = "langCng" lang="en">Product</div>
          <div className = "langCng" lang="ja">製品</div>
          <div className = "langCng" lang="ru">Продукт</div>
          <div className = "langCng" lang="fr">Produit</div>
          <div className = "langCng" lang="de">Produkt</div>
          <div className = "langCng" lang="it">Prodotto</div>
          <div className = "langCng" lang="zh">产品</div>
          <div className = "langCng" lang="ko">제품</div>
          <div className = "langCng" lang="fi">Tuote</div>
          <div className = "langCng" lang="es">Producto</div>
          <div className = "langCng" lang="tr">Ürün</div>
          <div className = "langCng" lang="ms">produk</div>
          <div className = "langCng" lang="ta">தயாரிப்பு</div>

          <div className = "langCng" lang="id">Produk</div>
          <div className = "langCng" lang="pt">produtos</div>
          <div className = "langCng" lang="ar">منتج</div>
          <div className = "langCng" lang="hi">उत्पाद</div>
        </div>
    );
  }