import React from 'react';

export default function CRouletteContant() {
    return (
        <div >
          <div className = "langCng" lang="en">C-Dice is Cheating Roulette.</div>
          <div className = "langCng" lang="ja">C-Roulette はイカサマルーレットです</div>
          <div className = "langCng" lang="ru">C-Roulette — это мошенническая рулетка.</div>
          <div className = "langCng" lang="fr">La C-Roulette est une tricherie à la roulette.</div>
          <div className = "langCng" lang="de">C-Roulette ist betrügerisches Roulette.</div>
          <div className = "langCng" lang="it">C-Roulette è Cheating Roulette.</div>
          <div className = "langCng" lang="zh">C-Roulette 是作弊轮盘赌。</div>
          <div className = "langCng" lang="ko">C-Roulette 치팅 룰렛입니다.</div>
          <div className = "langCng" lang="fi">C-Roulette on huijausruletti.</div>
          <div className = "langCng" lang="es">C-Roulette es una ruleta trampa.</div>
          <div className = "langCng" lang="tr">C-Rulet Hile Ruletidir.</div>
          <div className = "langCng" lang="ms">C-Roulette ialah Roulette Menipu.</div>
          <div className = "langCng" lang="ta">சி-ரவுலட் என்பது ஏமாற்றும் சில்லி.</div>
          <div className = "langCng" lang="id">C-Roulette adalah Roulette Kecurangan.</div>
          <div className = "langCng" lang="pt">C-Dice é um dado de trapaça.</div>
          <div className = "langCng" lang="ar">C-Roulette هي لعبة خداع للروليت.</div>
          <div className = "langCng" lang="hi">सी-रूलेट धोखा देने वाला रूले है।</div>
        </div>
    );
  }