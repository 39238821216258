import React from 'react';
import Social from '../Social';
import '../../assets/sass/_masthead.scss';
export default function Contact() {
    return (
        <div >
          <div className = "langCng" lang="en">CONTACT</div>
          <div className = "langCng" lang="ja">連絡</div>
          <div className = "langCng" lang="ru">КОНТАКТ</div>
          <div className = "langCng" lang="fr">CONTACT</div>
          <div className = "langCng" lang="de">KONTAKT</div>
          <div className = "langCng" lang="it">CONTATTO</div>
          <div className = "langCng" lang="zh">接触</div>
          <div className = "langCng" lang="ko">연락</div>
          <div className = "langCng" lang="fi">OTTAA YHTEYTTÄ</div>
          <div className = "langCng" lang="es">CONTACTO</div>
          <div className = "langCng" lang="tr">İLETIŞIM</div>
          <div className = "langCng" lang="ms">KENALAN</div>
          <div className = "langCng" lang="ta">தொடர்பு</div>
          <div className = "langCng" lang="id">KONTAK</div>
          <div className = "langCng" lang="pt">CONTATO</div>
          <div className = "langCng" lang="ar">اتصل</div>
          <div className = "langCng" lang="hi">संपर्क</div>
        </div>
    );
  }