import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import ccoinimage from '../../assets/images/portfolio_ccoin.png';
import CCOinContant from '../other_language/productcard/CCoinContant';
export default function CCoinCard() {
  return (
    <a href='https://c-coin.info/'>
    <Card>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={ccoinimage}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            C-Coin
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <CCOinContant/>
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
    </a>
  );
}
