import React from 'react';

export default function CCOinContant() {
    return (
        <div >
          <div className = "langCng" lang="en">C-Coin is Cheating Coin.</div>
          <div className = "langCng" lang="ja">C-Coin はイカサマコインです。</div>
          <div className = "langCng" lang="ru">C-Coin — мошенническая монета.</div>
          <div className = "langCng" lang="fr">C-Coin est une pièce de triche.</div>
          <div className = "langCng" lang="de">C-Coin ist Betrugsmünze.</div>
          <div className = "langCng" lang="it">C-Coin è Cheating Coin.</div>
          <div className = "langCng" lang="zh">C-Coin 这是一种倒置的硬币。</div>
          <div className = "langCng" lang="ko">C-Coin은 어색한 동전입니다.</div>
          <div className = "langCng" lang="fi">C-Coin on huijauskolikko.</div>
          <div className = "langCng" lang="es">C-Coin es una moneda trampa.</div>
          <div className = "langCng" lang="tr">C-Coin Hile Parasıdır.</div>
          <div className = "langCng" lang="ms">C-Coin ialah Syiling Menipu.</div>
          <div className = "langCng" lang="ta">சி-காயின் ஒரு ஏமாற்று நாணயம்.</div>
          <div className = "langCng" lang="id">C-Coin adalah Koin Kecurangan.</div>
          <div className = "langCng" lang="pt">C-Coin é uma moeda de trapaça.</div>
          <div className = "langCng" lang="ar">C- كوين dهي عملة الغش.</div>
          <div className = "langCng" lang="hi">सी-सिक्का धोखा देने वाला सिक्का है।</div>
        </div>
    );
  }