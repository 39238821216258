import React from 'react';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Social from '../components/Social';
import Contact from '../components/other_language/Contact';
import CDiceCard from '../components/product_card/CdiceCard';
import Grid from '@mui/material/Grid';
import homemovie from '../assets/movie/homemovie.mp4';
import homeimage from '../assets/img/movie_alt.png';
import '../assets/sass/_masthead.scss';
import '../assets/sass/_movie.scss';
import CCoinCard from '../components/product_card/CCOinCard';
import CRouletteCard from '../components/product_card/CRouletteCard';

if (typeof window !== "undefined") {
  window.onload = () => {
    const selectedLanguage = window.location.search.substring(1);
    if(selectedLanguage){
      localStorage.setItem("Language", selectedLanguage);
    }
  }
}

const IndexPage = () => (
  <Layout>
    <Header />

    <header className="masthead">
    <div className=" h-100 my-auto video_wrapper" id="home">
        <video src={homemovie}  muted autoPlay playsInline alt={homeimage} poster={homeimage}></video>
    </div>
    </header>

    <section className="features" id="features">
    {/* <CDiceCard /> */}
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <CDiceCard />
      </Grid>
      <Grid item xs={4}>
        <CCoinCard/>
      </Grid>
      <Grid item xs={4}>
        <CRouletteCard />
      </Grid>
    </Grid>

    </section>
    <section className="contact colorchg" id="contact">
      <div className="container">
        <h2><Contact/></h2>
        <Social />
      </div>
    </section>
  </Layout>
);

export default IndexPage;
